import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';
import { JobCard } from '../../components';
import formatJobs from '../../utils/format-jobs';

const Main = styled.div`
  width: calc(100% - 300px );

  @media (max-width: ${theme.breakpoints.s}) {
    padding: 2rem;
    width: 100%;
  }

  .countrycountainer {
    + .countrycountainer {
      margin-top: 4rem;
    }
  }

  .country {
    text-transform: capitalize;
    color: ${theme.colors.slate.light30};
    font-size: ${theme.fontSize.medium};
  }

  h3 {
    text-transform: capitalize;
    font-size: ${theme.fontSize.xlarge};
    margin-bottom: 0;
    margin-top: 3rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${theme.colors.slate.light10};

    @media (max-width: ${theme.breakpoints.s}) {
      font-size: ${theme.fontSize.large};
      margin-top: 1.5rem;
      padding-bottom: 1rem;
      display: flex;
      justify-content: space-between;

      &.active {
        + .cardcontainer {
          max-height: 1000vh;
        }
      }
    }

    span {
      display: none;

      @media (max-width: ${theme.breakpoints.s}) {
        display: block;
        color: ${theme.colors.slate.light10};
      }
    }
  }

  .cardcontainer {
    @media (max-width: ${theme.breakpoints.s}) {
      padding-left: 1rem;
      overflow: hidden;
      transition: ${theme.transitions.default};
      max-height: 0;
    }
  }
`;

class JobList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleList = (e, key) => {
    e.preventDefault();
    this.setState({ [key]: !this.state[key] });
  };

  render() {
    const { edges } = this.props.data.allMarkdownRemark;
    const jobList = formatJobs(edges);

    return (
      <Main>
        {jobList.map(country => (
          <div key={country.country} className="countrycountainer">
            <h2 className="country">{country.country}</h2>
            {country.department.map(department => (
              <div key={department.name}>
                <h3
                  onClick={e =>
                    this.toggleList(e, `${country.country}_${department.name}`)
                  }
                  className={
                    !!this.state[`${country.country}_${department.name}`] ?
                    'active' : undefined
                  }
                  id={department.name}
                >
                  {department.name}
                  {this.state[`${country.country}_${department.name}`] ? (
                    <span className="material-icons">keyboard_arrow_up</span>
                  ) : (
                    <span className="material-icons">keyboard_arrow_down</span>
                  )}
                </h3>
                <div className="cardcontainer">
                  {department.node.map(node => (
                    <JobCard
                      key={node.id}
                      path={node.frontmatter.path}
                      title={node.frontmatter.title}
                      titleJp={node.frontmatter.titleJp}
                      employmentType={node.frontmatter.employmentType}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </Main>
    );
  }
}

export default JobList;

JobList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              titleJp: PropTypes.string.isRequired,
              employmentType: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};
