import React, { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import Headroom from 'react-headroom';
import theme from '../../config/theme';
import logo from '../../static/logo/repro-logo-colored.svg';
import LogoWhite from '../assets/repro-logo-white.svg';
import { LocalizedLink, LocaleToggle } from 'components';

const StyledLink = styled(props => <LocalizedLink {...props} />)`
  display: flex;
  font-weight: 700;
  align-items: center;

  img {
    margin: 1.5rem 0 1rem;
    height: 1.5rem;
    transition: ${theme.transitions.default};
    &:hover {
      opacity: 0.7;
    }

    @media (max-width: ${theme.breakpoints.s}) {
      margin: 5px;
    }
  }

  svg {
    margin: 1.5rem 0 1rem;
    height: 1.5rem;
    transition: ${theme.transitions.default};
    &:hover {
      opacity: 0.7;
    }

    @media (min-width: 769px) {
      g {
        fill: ${theme.colors.black.base};

        path {
          fill: ${theme.colors.black.base} !important;
        }
      }
    }

    @media (max-width: ${theme.breakpoints.s}) {
      margin: 5px;
    }
  }
`;

const navStyles = css`
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
  align-items: center;
  margin-top: 0.5rem;
  margin-right: 0.75rem;

  @media (max-width: ${theme.breakpoints.s}) {
    display: none;
    margin-top: 0;
    margin-right: 0;
  }

  a {
    color: ${theme.colors.black.base};
    margin-left: 2rem;
    transition: all ${theme.transitions.default.duration};
    text-transform: uppercase;
    letter-spacing: ${theme.letterSpacing.medium};
    font-size: ${theme.fontSize.small};
    font-family: ${theme.fontFamily.heading};

    &:hover {
      color: ${theme.colors.white.grey};
    }
  }
`;

const showMenuStyles = css`
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    position: fixed;
    padding: 1rem 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: ${theme.colors.white.base};
    animation: anime 0.3s ease;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;

    a, >div {
      display: block;
      padding: 1rem 2rem;
      margin: 0;
    }

    @keyframes anime {
      0% {
        opacity: 0;
        transform: translateY(-10px);
      }
      100% {
        opacity: 100%;
        transform: translateY(0px);
      }
    }
  }
`;

const IconLink = styled.div`
  display: none;

  @media (max-width: ${theme.breakpoints.s}) {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 100;
  }

  span {
    margin: 1.5rem 0 1rem;
    color: ${props =>
      props.headerWhite ? theme.colors.white.base : theme.colors.black.base};

    @media (max-width: ${theme.breakpoints.s}) {
      margin: 0;
    }
  }
`;


const Navbar = ({
  headerWhite,
  defaultSlug,
  supportedLangs
}) => {

  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => setShowMenu(!showMenu)

  return (
    <Headroom
      calcHeightOnResize
      disableInlineStyles
    >
      <StyledLink to="/">
        {headerWhite ? <LogoWhite /> : <img src={logo} alt="Repro Logo" />}
      </StyledLink>
      <nav css={[navStyles, showMenu && showMenuStyles]}>
        <LocalizedLink to="/business">Business</LocalizedLink>
        <LocalizedLink to="/people">People</LocalizedLink>
        <LocalizedLink to="/community">Community</LocalizedLink>
        <a href="https://herp.careers/v1/repro" target="_blank">Jobs</a> 
        <LocalizedLink to="/events">Events</LocalizedLink>
        <LocaleToggle defaultSlug={defaultSlug} supportedLangs={supportedLangs} />
      </nav>

      <IconLink onClick={toggleMenu} headerWhite={headerWhite}>
        {showMenu ? (
          <span className="material-icons">close</span>
        ) : (
          <span className="material-icons">menu</span>
        )}
      </IconLink>
    </Headroom>
  )
}

export default Navbar
