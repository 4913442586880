import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
const scrollToElement = require('scroll-to-element');

const handleLinkClick = (e, target) => {
  e.preventDefault();
  scrollToElement(target, {
    offset: -50,
    duration: 1000,
  })
}

const TextLink = ({ title, to }) => {
  if (to.slice(0, 1) === '#') {
    return <Link onClick={e => handleLinkClick(e, to)} to={`/${to}`}>{title}</Link>;
  } else {
    return <Link to={to}>{title}</Link>;
  }
};

export default TextLink;

TextLink.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
};
