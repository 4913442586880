export default function formatJobs(jobNodes) {
  const jobList = [];
  jobNodes.forEach(({ node }) => {
    if (node.frontmatter.country && node.frontmatter.department) {
      const { country, department } = node.frontmatter;
      if (!jobList.find(elem => elem.country === country)) {
        jobList.push({ country, department: [] });
      }
      if (
        !jobList
          .find(elem => elem.country === country)
          .department.find(elem => elem.name === department)
      ) {
        jobList
          .find(elem => elem.country === country)
          .department.push({ name: department, node: [] });
      }
      jobList
        .find(elem => elem.country === country)
        .department.find(elem => elem.name === department)
        .node.push(node);
    }
  });
  return jobList;
}
