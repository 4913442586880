import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';
import { TextLink } from '../../components';
import formatJobs from '../../utils/format-jobs';

const Sidebar = styled.div`
  padding-left: 4rem;
  flex: 0;
  right: 0;
  position: absolute;

  &.fixed {
    position: fixed;
    top: 100px;

    @media (max-width: 1280px) {
      right: 5%;
    }
    @media (min-width: 1281px) {
      right: 50%;
      transform: translateX(640px);
    }
  }

  h1 {
    color: ${theme.colors.slate.light30};
    font-size: ${theme.fontSize.medium};
    letter-spacing: ${theme.letterSpacing.wide};
  }

  h2 {
    font-size: ${theme.fontSize.medium};
    margin: 1.5rem 0 1rem;
    text-transform: capitalize;
  }

  a {
    display: block;
    color: ${theme.colors.slate.base};
    padding: 0.3rem 0;
    text-transform: capitalize;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    display: none;
  }
`;

class JobSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fixed: false };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    const headerElement = document.getElementById('header');
    const rect = headerElement.getBoundingClientRect();
    const tirggerHeight = rect.top + rect.height + window.pageYOffset;
    let currentScrollPos = window.pageYOffset;
    if (currentScrollPos > tirggerHeight) {
      document.getElementById('sidebar').classList.add('fixed');
    } else {
      document.getElementById('sidebar').classList.remove('fixed');
    }
  };

  render() {
    const { edges } = this.props.data.allMarkdownRemark;
    const jobList = formatJobs(edges);

    return (
      <Sidebar id="sidebar">
        <h1>DEPARTMENTS</h1>
        {jobList.map(country => (
          <div key={`side${country.country}`}>
            <h2>{country.country}</h2>
            {country.department.map(department => (
              <div key={`side${department.name}`}>
                <TextLink to={`#${department.name}`} title={department.name} />
              </div>
            ))}
          </div>
        ))}
      </Sidebar>
    );
  }
}

export default JobSidebar;

JobSidebar.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              titleJp: PropTypes.string.isRequired,
              employmentType: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};
