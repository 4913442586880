import React, { useState, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';
import { Card, FeaturedJobsCard } from '../../components';
import { I18nContext } from '../../layouts/Layout'

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -2rem;

  @media (max-width: 1000px) {
    margin: 0 2rem;
    justify-content: space-between;
  }
  @media (max-width: 700px) {
    margin: 4rem 1rem 1rem 1rem;
  }

  .buttoncontainer {
    text-align: center;
    width: 100%;
  }
`;

const ButtonLink = styled.button`
  color: ${theme.colors.primary.base};
  border: 2px solid ${theme.colors.primary.base};
  letter-spacing: ${theme.letterSpacing.medium};
  text-transform: uppercase;
  font-family: ${theme.fontFamily.heading};
  font-size: ${theme.fontSize.medium};
  display: inline-block;
  min-width: ${props => (props.size === 'small' ? '10rem' : '18rem')};
  border-radius: 3px;
  padding: 1rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: ${theme.transitions.default};
  z-index: 1;

  &:before {
    position: absolute;
    transition: ${theme.transitions.default};
    content: '';
    width: 0;
    left: 50%;
    bottom: 0;
    height: 3px;
    background: ${theme.colors.primary.base};
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: -1;
  }

  &:hover {
    cursor: pointer;
    color: ${theme.colors.white.base};
    &:before {
      width: 100%;
      left: 0;
      left: -10%;
      width: 120%;
    }
  }
`;

const PER_PAGE = 15;

const CardList = ({ list, featuredJobNodes, insertIndexForFeaturedJobs }) => {
  const i18n = useContext(I18nContext)
  const [page, setPage] = useState(1)

  const modifiedList = useMemo(() => {
    if (!featuredJobNodes || featuredJobNodes.length === 0) {
      return [...list];
    }

    // featuredJobsをリストに挿入
    if (list.length <= insertIndexForFeaturedJobs) {
      return [...list, featuredJobNodes];
    }

    // propsの値を変更させないために、破壊的メソッドspliceは使わない
    return [
      ...list.slice(0, insertIndexForFeaturedJobs),
      featuredJobNodes, // Jobの配列だが１つのカードに表示するので配列の１つの要素として扱う
      ...list.slice(insertIndexForFeaturedJobs + 1),
    ];

  }, [ list, featuredJobNodes, insertIndexForFeaturedJobs ])

  const filtered = useMemo(() => modifiedList.slice(0, page * PER_PAGE), [ modifiedList, page ]);

  function hasNextPage() {
    return page * PER_PAGE < modifiedList.length;
  }

  function loadNextPage() {
    if (!hasNextPage()) {
      return;
    }

    setPage(page + 1)
  }

  function isFeaturedList(factor) {
    return Array.isArray(factor);
  }



  return (
    <CardsWrapper>
      {filtered.map(factor => {
        return isFeaturedList(factor)
          ? <FeaturedJobsCard key="featuredJob" featuredJobNodes={factor}/>
          : <Card
              key={factor.node.id}
              cover={factor.node.frontmatter.cover.childImageSharp.fluid}
              path={factor.node.frontmatter.path}
              title={factor.node.frontmatter.title}
              date={factor.node.frontmatter.date}
              tags={factor.node.frontmatter.tags}
            />
      })}
      {hasNextPage() ? (
        <div className="buttoncontainer">
          <ButtonLink onClick={loadNextPage}>{i18n.t("button.loadMore")}</ButtonLink>
        </div>
      ) : null}
    </CardsWrapper>
  );
}

CardList.propTypes = {
  list: PropTypes.array,
  featuredJobNodes: PropTypes.array,
  insertIndexForFeaturedJobs: PropTypes.number,
};

export default CardList;








