const locales = {
  ja: {
    path: 'ja',
    locale: 'Japanese',
    default: true,
  },
  en: {
    path: 'en',
    locale: 'English',
  },
}

const defaultLocalePath = Object.keys(locales).map(key => locales[key]).find(l => l.default).path
const allSupportedLangs = Object.keys(locales).map(key => locales[key].path)

module.exports = {
  locales,
  defaultLocalePath,
  allSupportedLangs,
}
