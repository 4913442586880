import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import config from '../../../config/site';
import { LocaleContext } from '../../layouts/Layout'

const SEO = ({
  title,
  desc,
  banner,
  pathname,
  article,
}) => {

  const locale = useContext(LocaleContext)

  const {
    title: defaultTitle,
    titleAlt,
    shortName,
    author,
    siteLanguage,
    logo,
    siteUrl,
    pathPrefix,
    defaultDescription,
    bannerForSEO,
    twitter,
  } = config[locale]

  const {
    site: {
      buildTime,
    },
  } = useStaticQuery(graphql`
    query SEO {
      site {
        buildTime(formatString: "YYYY-MM-DD")
      }
    }
  `)

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${pathPrefix}${banner || bannerForSEO}`,
    url: `${siteUrl}${pathname || '/'}`,
  };
  const realPrefix = pathPrefix === '/' ? '' : pathPrefix;
  let schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      '@id': siteUrl,
      url: siteUrl,
      name: defaultTitle,
      alternateName: titleAlt || '',
    },
  ];
  if (article) {
    schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        '@id': seo.url,
        url: seo.url,
        name: title,
        alternateName: titleAlt || '',
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: seo.image,
        },
        description: seo.description,
        datePublished: buildTime,
        dateModified: buildTime,
        author: {
          '@type': 'Organization',
          name: author,
          logo: {
            '@type': 'ImageObject',
            url: siteUrl + realPrefix + logo,
          },
        },
        publisher: {
          '@type': 'Organization',
          name: author,
          logo: {
            '@type': 'ImageObject',
            url: siteUrl + realPrefix + logo,
          },
        },
        isPartOf: siteUrl,
        mainEntityOfPage: {
          '@type': 'WebSite',
          '@id': siteUrl,
        },
      },
    ];
  }


  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="apple-mobile-web-app-title" content={shortName} />
        <meta name="application-name" content={shortName} />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {/* OpenGraph  */}
        <meta property="og:url" content={seo.url} />
        <meta
          property="og:type"
          content={article ? 'article' : 'website'}
        />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitter} />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
      </Helmet>
    </>
  );
}

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
};
