import { css } from '@emotion/core';
import theme from '../../config/theme';

const headroom = css`
  .headroom-wrapper {
    position: fixed;
    width: 100%;
    z-index: 2000;
    top: 0;
  }
  .headroom {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 1rem 1.5rem;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
    transition: ${theme.transitions.headroom.transition};
  }
  .headroom--scrolled {
    transition: ${theme.transitions.headroom.transition};
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
    transition: ${theme.transitions.headroom.transition};
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0);
    transition: ${theme.transitions.headroom.transition};
    background-color: ${theme.colors.white.base};
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    @media (max-width: ${theme.breakpoints.s}) {
      svg {
        fill: ${theme.colors.black.base};
        path {
          fill: ${theme.colors.black.base} !important;
        }
      }
    }
    .material-icons,
    span {
      color: ${theme.colors.black.base};
    }
  }
`;

export default headroom;
