import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';

const Date = styled.span`
  color: ${theme.colors.slate.light30};
  letter-spacing: ${theme.letterSpacing.medium};
  font-size: ${props => props.size ? theme.fontSize[props.size] : theme.fontSize.medium};
`;

const FormatterdDate = ({ date, size }) => {
  const formattedDateString = date.replace(/-/g, '.');
  return <Date size={size}>{formattedDateString}</Date>;
};

export default FormatterdDate;

FormatterdDate.propTypes = {
  date: PropTypes.string,
  size: PropTypes.string,
};
