import React, { useContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { I18nContext } from '../../layouts/Layout'

const UpcomingEvents = () => {

  const i18n = useContext(I18nContext)

  return (
    <div>
      <div>{i18n.t("no upcoming event")}</div>
    </div>
  );
}

export default UpcomingEvents;
