import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';
import LocalizedLink from '../atoms/LocalizedLink';

const CategoriesContainer = styled.div`
  flex-wrap: wrap;
`;

const CategoriesLink = styled(LocalizedLink)`
  margin-bottom: 1rem;
  color: ${props => props.color === 'secondary' ? theme.colors.slate.base : theme.colors.primary.base};
  letter-spacing: ${theme.letterSpacing.medium};
  font-weight: bold;
  font-size: ${props => props.size === 'small' ? theme.fontSize.small : theme.fontSize.medium};
  font-family: ${theme.fontFamily.heading};
  text-transform: uppercase;
  &:hover {
    color: ${theme.colors.primary.dark};
  }

  + a {
    margin-left: 1rem;
  }
`;


const CategoriesBlock = ({ list, size, color }) => (
  <CategoriesContainer>
    {list &&
      list.map(category => {
        const upperCategory = category.charAt(0).toUpperCase() + category.slice(1);
        return (
          <CategoriesLink key={category} to={`/${category}`} size={size} color={color}>
            <span>{upperCategory}</span>
          </CategoriesLink>
        );
      })}
  </CategoriesContainer>
);

export default CategoriesBlock;

CategoriesBlock.propTypes = {
  list: PropTypes.array,
  size: PropTypes.string,
  color: PropTypes.string,
};
