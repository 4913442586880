import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import PrevNextCard from '../molecules/PrevNextCard';

const NestPrevCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -2rem 8rem;

  @media (max-width: 1000px) {
    margin: 0 4rem 6rem;
    justify-content: space-between;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    margin: 4rem 1rem 1rem 1rem;
  }
`;


const PrevNextCards = ({prev, next }) => {
  return (
    <NestPrevCardsContainer>
      <PrevNextCard isPrev={true} post={prev} />
      <PrevNextCard isPrev={false} post={next} />
    </NestPrevCardsContainer>
  );
}

export default PrevNextCards;

PrevNextCards.propTypes = {
  prev: PropTypes.object,
  next: PropTypes.object,
};

