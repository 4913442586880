import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import axios from 'axios';
import { Formik, Field } from 'formik';
import { Container } from 'layouts';
import FormControlMessage from './FormControlMessage';
import theme from '../../../config/theme';
import { I18nContext } from '../../layouts/Layout'

const MAX_SIZE_OF_ATTACHMENT_LABAL = 800;
const MAX_SIZE_OF_ATTACHMENT = MAX_SIZE_OF_ATTACHMENT_LABAL * 1000 * 1000;

const FormGroup = styled.div`
  display: flex;
  padding: 0 6rem 2rem;
  margin-bottom: 12px;

  @media (max-width: ${theme.breakpoints.s}) {
    padding: 0 2rem 1rem;
    display: block;
  }
`;

const Label = styled.div`
  width: 40%;
  font-weight: bold;
  font-family: ${theme.fontFamily.heading};

  @media (max-width: ${theme.breakpoints.s}) {
    width: 100%;
    margin-bottom: .5rem;
  }
`;

const FormControl = styled.div`
  width: 60%;

  @media (max-width: ${theme.breakpoints.s}) {
    width: 100%;
  }

  input[type='text'],
  input[type='email'],
  textarea {
    padding: 0.5rem 0.8rem;
    border: 1px solid #e1e1e1;
    max-width: 100%;
    width: 350px;

    ::placeholder {
      color: #e1e1e1;
    }
  }

  input[type='file'] {
    &::-webkit-file-upload-button {
      color: ${theme.colors.slate.light40};
      border: 1px solid ${theme.colors.slate.light40};
    }
  }

  textarea {
    width: 100%;
  }
`;

const FormAction = styled.div`
  padding: 0 6rem;

  @media (max-width: ${theme.breakpoints.s}) {
    padding: 0 2rem;
    text-align: center;
  }
  button {
    margin-left: 40%;
    color: ${theme.colors.primary.base};
    border: 2px solid ${theme.colors.primary.base};
    letter-spacing: ${theme.letterSpacing.medium};
    text-transform: uppercase;
    font-family: ${theme.fontFamily.heading};
    font-size: ${theme.fontSize.medium};
    display: inline-block;
    min-width: ${props => (props.size === 'small' ? '10rem' : '18rem')};
    border-radius: 3px;
    padding: 1rem;
    text-align: center;
    background: transparent;
    position: relative;
    overflow: hidden;
    transition: ${theme.transitions.default};
    z-index: 1;

    @media (max-width: ${theme.breakpoints.s}) {
      margin: 2rem 0 0;
    }

    &:before {
      position: absolute;
      transition: ${theme.transitions.default};
      content: '';
      width: 0;
      left: 50%;
      bottom: 0;
      height: 3px;
      background: ${theme.colors.primary.base};
      height: 120%;
      left: -10%;
      transform: skewX(15deg);
      z-index: -1;
    }

    &:hover {
      cursor: pointer;
      color: ${theme.colors.white.base};
      &:before {
        width: 100%;
        left: 0;
        left: -10%;
        width: 120%;
      }
    }
  }
`;

const handleValidate = (i18n, values) => {
  let errors = {};

  // job
  // novalidation

  // fullname
  if (!values.fullname) {
    errors.fullname = i18n.t("jobForm.validate.required", { label: i18n.t("jobForm.label.fullname")});
  } else if (!values.fullname.match(/^[A-Za-z ]*$/)) {
    errors.fullname = i18n.t("jobForm.validate.alphabet", { label: i18n.t("jobForm.label.fullname")});
  }

  // email
  if (!values.email) {
    errors.email = i18n.t("jobForm.validate.required", { label: i18n.t("jobForm.label.email") });
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = i18n.t("jobForm.validate.type", { label: i18n.t("jobForm.label.email")});
  }

  // cvUpload
  if (!values.cvUpload) {
    errors.cvUpload = i18n.t("jobForm.validate.requiredFile", { label: i18n.t("jobForm.label.cvUpload") });
  } else if (values.cvUpload.size > MAX_SIZE_OF_ATTACHMENT) {
    errors.cvUpload = i18n.t("jobForm.validate.fileMaxSize", { size: `${MAX_SIZE_OF_ATTACHMENT_LABAL}MB`})
  }

  // otherSkills
  // novalidation

  // otherUpload
  if (values.otherUpload && values.otherUpload.size > MAX_SIZE_OF_ATTACHMENT) {
    errors.otherUpload = i18n.t("jobForm.validate.fileMaxSize", { size: `${MAX_SIZE_OF_ATTACHMENT_LABAL}MB`});
  }

  return errors;
};

const handleSubmit = (i18n, values, actions) => {
  const params = new FormData();
  params.append('job', values.job);
  params.append('form-name', values['form-name']); // for Netlify Form
  params.append('bot-field', values['bot-field']); // for Netlify Form
  params.append('fullname', values.fullname);
  params.append('email', values.email);
  params.append('cvUpload', values.cvUpload);
  params.append('otherSkills', values.otherSkills);
  params.append('otherUpload', values.otherUpload);

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: params,
    url: '/',
  };

  actions.setSubmitting(true);

  axios(options)
    .then(res => {
      navigate('/application-complete');
      actions.resetForm();
      actions.setSubmitting(false);
    })
    .catch(err => {
      actions.setSubmitting(false);
      alert(i18n.t("jobForm.message.failApplication"));
    });
};

const renderForm = (i18n, { handleSubmit, dirty, isSubmitting, setFieldValue }) => (
  <form
    name="contact"
    method="POST"
    novalidate="true"
    data-netlify="true" // for Netlify Form
    netlify-honeypot="bot-field" // for Netlify Form
    onSubmit={handleSubmit}
  >
    <Field type="hidden" name="bot-field" />
    <Field type="hidden" name="form-name" />
    <Field type="hidden" name="job" />

    <FormGroup>
      <Label>{i18n.t("jobForm.label.fullname")}*</Label>
      <FormControl>
        <Field type="text" name="fullname" placeholder="Hanako Yamada" />
        <FormControlMessage name="fullname" hint={i18n.t("jobForm.validate.alphabet", { label: i18n.t("jobForm.label.fullname") })} />
      </FormControl>
    </FormGroup>

    <FormGroup>
      <Label>{i18n.t("jobForm.label.email")}*</Label>
      <FormControl>
        <Field type="email" name="email" placeholder="example@repro.io"  />
        <FormControlMessage
          name="email"
          hint={i18n.t("jobForm.hint.email")}
        />
      </FormControl>
    </FormGroup>

    <FormGroup>
      <Label>{i18n.t("jobForm.label.cvUpload")}*</Label>
      <FormControl>
        <input
          id="cvUpload"
          name="cvUpload"
          type="file"
          onChange={event =>
            setFieldValue('cvUpload', event.currentTarget.files[0])
          }
        />
        <FormControlMessage
          name="cvUpload"
          hint={i18n.t("jobForm.validate.fileMaxSize", { size: `${MAX_SIZE_OF_ATTACHMENT_LABAL}MB`})}
        />
      </FormControl>
    </FormGroup>

    <FormGroup>
      <Label>{i18n.t("jobForm.label.otherSkills")}</Label>
      <FormControl>
        <Field
          component="textarea"
          name="otherSkills"
          placeholder={i18n.t("jobForm.hint.otherSkills")}
          rows="6"
        />
        <FormControlMessage name="otherSkills" />
      </FormControl>
    </FormGroup>

    <FormGroup>
      <Label>{i18n.t("jobForm.label.otherUpload")}</Label>
      <FormControl>
        <input
          id="otherUpload"
          name="otherUpload"
          type="file"
          onChange={event =>
            setFieldValue('otherUpload', event.currentTarget.files[0])
          }
        />
        <FormControlMessage
          name="otherUpload"
          hint={i18n.t("jobForm.hint.otherUpload")}
        />
      </FormControl>
    </FormGroup>

    <FormAction>
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? i18n.t("jobForm.button.submitting") : i18n.t("jobForm.button.notYetSubmit")}
      </button>
    </FormAction>
  </form>
);

const JobForm = ({ job }) => {
  const i18n = useContext(I18nContext)

  return (
    <Container type="article">
      <Formik
        initialValues={{
          'form-name': 'contact', // for Netlify Form
          'bot-field': '', // for Netlify Form
          job,
          fullname: '',
          email: '',
          cvUpload: null,
          otherSkills: '',
          otherUpload: null,
        }}
        validate={values => handleValidate(i18n, values)}
        onSubmit={(values, actions) => handleSubmit(i18n, values, actions)}
        render={arg => renderForm(i18n, arg)}
      />
    </Container>
  );
}

export default JobForm;

JobForm.propTypes = {
  job: PropTypes.string.isRequired,
};
