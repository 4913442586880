import React from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Container } from '../../layouts';
import theme from '../../../config/theme';
import { RandomCoverImage } from '../../components';

const Wrapper = styled.div`
  position: relative;

  @media (max-width: ${theme.breakpoints.s}) {
    margin-top: -64px;
  }

  &:before {
    content: '';
    background: ${theme.colors.white.light};
    display: block;
    position: absolute;
    z-index: -1;
    top: 6rem;
    width: 100%;
    bottom: -6rem;
    animation: anime 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform-origin: left center;

    @media (max-width: ${theme.breakpoints.s}) {
      top: 0;
      bottom: -10rem;
    }

    @keyframes anime {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  &:after {
    @media (max-width: ${theme.breakpoints.s}) {
      content: '';
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 80px;
    }
  }
`;

const Hider = styled.div`
  @media (max-width: ${theme.breakpoints.s}) {
    overflow-x: hidden;
    margin-top: -64px;
  }
`;

const randomCoverWrapperStyle = css`
  @media (max-width: ${theme.breakpoints.s}) {
    margin: 0 -10rem;
  }
`;


const wrapperStyle = css`
  @media (max-width: ${theme.breakpoints.s}) {
    margin: 0;
  }
`;


const beforeLoadStyle = css`
  opacity: 0;
  img {
    opacity: 0;
    transform: scale(1.1);
  }
`;

const afterLoadStyle = css`
  img {
    opacity: 100%;
    animation: anime-image 0.5s ease-in;

    @keyframes anime-image {
      0% {
        opacity: 0;
        transform: scale(1.1);
      }
      50% {
        opacity: 1;
        transform: scale(1.1);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`;

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasLoaded: false };
  }

  onLoadImage = () => {
    const { hasLoaded } = this.state;
    if (hasLoaded) return;
    this.setState({ hasLoaded: true });
  };

  render() {
    const { hasLoaded } = this.state;
    const { isRandomCover } = this.props;
    return (
      <Wrapper>
        <Hider>
          <Container type="big">
            <div
              css={[isRandomCover ? randomCoverWrapperStyle: wrapperStyle, hasLoaded ? afterLoadStyle : beforeLoadStyle]}
            >
              {isRandomCover ? (
                <RandomCoverImage
                  onLoad={this.onLoadImage}
                  placeholderStyle={{ display: 'none' }}
                />
              ) : (
                <Img
                  fluid={this.props.cover}
                  onLoad={this.onLoadImage}
                  placeholderStyle={{ display: 'none' }}
                />
              )}
            </div>
          </Container>
        </Hider>
      </Wrapper>
    );
  }
}

export default Hero;

Hero.propTypes = {
  cover: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isRandomCover: PropTypes.bool,
};

Hero.defaultProps = {
  cover: false,
  isRandomCover: false,
};
