import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';

const Wrapper = styled.article`
  margin-bottom: 2rem;
  transition: ${props => props.theme.transitions.boom.transition};
  flex-basis: 33.33%;
  max-width: 33.33%;
  width: 33.33%;
  padding: 2rem;
  background: ${theme.colors.background.light};

  @media (max-width: 1000px) {
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
    padding: 1rem;
  }

  @media (max-width: 700px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
  }
`;


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.slate.light10};
  margin-bottom: 1rem;
  padding-bottom: 0.2rem;
`;

const CardTitle = styled.h2`
  color: ${theme.colors.slate.light30};
  font-size: ${theme.fontSize.large};
  line-height: ${theme.lineHeight.heading};
  transition: ${theme.transitions.default};
  margin: 0;
`;


const LinkToAllJobs = styled(Link)`
  color: ${theme.colors.primary.base};
  display: block;
  font-weight: bold;
  font-size: ${theme.fontSize.xsmall};
  text-align: right;

  span {
    vertical-align: bottom;
  }

  &:hover {
    cursor: pointer;
  }
`

const Content = styled.div`
`;

const JobLink = styled(Link)`
  margin-bottom: 1rem;
  display: block;

  &:hover {
    h2 {
      color: ${theme.colors.slate.light30};
    }
    div {
      color: ${theme.colors.slate.light20};
    }
  }
`

const JobTitle = styled.h2`
  margin-bottom: .2rem;
  color: ${props => props.theme.colors.black.base};
  font-size: ${theme.fontSize.medium};
  font-weight: bold;

  @media (max-width: 700px) {
    font-size: ${theme.fontSize.small};
  }
`;

const JobInfo = styled.div`
  color: ${theme.colors.slate.light40};
  font-size: ${theme.fontSize.small};

  @media (max-width: 700px) {
    font-size: ${theme.fontSize.xsmall};
  }
`;

const Job = ({ path, title, titleJp, employmentType, country }) => (
  <JobLink to={path}>
    <JobTitle>{title}</JobTitle>
    <JobInfo>{country} {employmentType}</JobInfo>
  </JobLink>
);

Job.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleJp: PropTypes.string.isRequired,
  employmentType: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};


const FeaturedJobsCard = ({ featuredJobNodes }) => (
  <Wrapper>
    <Header>
      <CardTitle>FEATURED JOBS</CardTitle>
      <LinkToAllJobs to={'/jobs'}><span>SEE ALL JOBS</span></LinkToAllJobs>
    </Header>
    <Content>
      {featuredJobNodes.map(node => (
        <Job
          key={node.id}
          path={node.frontmatter.path}
          title={node.frontmatter.title}
          titleJp={node.frontmatter.titleJp}
          employmentType={node.frontmatter.employmentType}
          country={node.frontmatter.country}
        />
      ))}
    </Content>
  </Wrapper>
);

export default FeaturedJobsCard;

FeaturedJobsCard.propTypes = {
  featuredJobNodes: PropTypes.array.isRequired,
};
