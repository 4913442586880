import React from 'react';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';
import { TagsBlock, FormattedDate, LocalizedLink } from '../../components';


const Wrapper = styled.article`
  margin-bottom: 2rem;
  transition: ${props => props.theme.transitions.boom.transition};
  flex-basis: ${props => props.isTFullWidth ? `100%` : `33.33%`};
  max-width: ${props => props.isTFullWidth ? `100%` : `33.33%`};
  width: ${props => props.isTFullWidth ? `100%` : `33.33%`};
  padding: 0 2rem;

  @media (max-width: 1000px) {
    flex-basis: ${props => props.isTFullWidth ? `100%` : `calc(99.9% * 1 / 2 - 1rem)`};
    max-width: ${props => props.isTFullWidth ? `100%` : `calc(99.9% * 1 / 2 - 1rem)`};
    width: ${props => props.isTFullWidth ? `100%` : `calc(99.9% * 1 / 2 - 1rem)`};
    padding: 0;
  }

  @media (max-width: 700px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
`;

const StyledLink = styled(props => <LocalizedLink {...props} />)`
  @media (max-width: 700px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  &:hover {
    .image {
      transform: scale(1.04);
    }

    h2 {
      color: ${theme.colors.slate.light30};
    }
  }
`;

const ImageContainer = styled.div`
  overflow: hidden;
  margin-bottom: 1rem;

  @media (max-width: 700px) {
    width: 30%;
    margin-bottom: 0;
  }

  .image {
    transition: ${theme.transitions.default};
  }
`;

const Info = styled.div`
  color: ${props => props.theme.colors.black.base};
  margin-bottom: 2rem;

  @media (max-width: 700px) {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .tag {
      display: none;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.black.base};
  font-size: ${theme.fontSize.large};
  font-weight: bold;
  line-height: ${theme.lineHeight.heading};
  transition: ${theme.transitions.default};

  @media (max-width: 700px) {
    width: 60%;
    margin-bottom: .5rem;
    font-size: ${theme.fontSize.small};
  }
`;

const Card = ({ cover, path, date, title, excerpt, tags, isTFullWidth }) => (
  <Wrapper isTFullWidth={isTFullWidth} >
    <StyledLink to={path}>
      <ImageContainer>
        <div className="image">
          <Img fluid={cover} />
        </div>
      </ImageContainer>
      <Title>{title}</Title>
    </StyledLink>
    <Info>
      <FormattedDate date={date} size="xsmall" />
      <div className="tag">
        <TagsBlock list={tags} size="xsmall" />
      </div>
    </Info>
  </Wrapper>
);

export default Card;

Card.propTypes = {
  cover: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tags: PropTypes.array,
};
