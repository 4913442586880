import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';
import LocalizedLink from '../atoms/LocalizedLink';
const TagsContainer = styled.div`
  flex-wrap: wrap;
`;

const TagsLink = styled(LocalizedLink)`
  margin-bottom: .5rem;
  color: ${props =>
    props.color === 'secondary'
      ? theme.colors.slate.base
      : theme.colors.primary.base};
  letter-spacing: ${theme.letterSpacing.medium};
  font-weight: bold;
  font-size: ${props =>
    props.size ? theme.fontSize[props.size] : theme.fontSize.medium};
  font-family: ${theme.fontFamily.heading};
  text-transform: uppercase;
  display: inline-block;
  margin-right: 1rem;

  &:hover {
    color: ${theme.colors.primary.dark};
  }
  &:before {
    content: '#';
    margin-right: 3px;
  }
`;

const TagsBlock = ({ list, size, color }) => (
  <TagsContainer>
    {list &&
      list.map(tag => {
        const upperTag = tag.charAt(0).toUpperCase() + tag.slice(1);
        return (
          <TagsLink key={tag} to={`/tags/${tag}`} size={size} color={color}>
            <span>{upperTag}</span>
          </TagsLink>
        );
      })}
  </TagsContainer>
);

export default TagsBlock;

TagsBlock.propTypes = {
  list: PropTypes.array,
  size: PropTypes.string,
  color: PropTypes.string,
};
