import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';

const Wrapper = styled.a`
  margin-bottom: 2rem;
  transition: ${props => props.theme.transitions.boom.transition};
  flex-basis: 33.33%;
  max-width: 33.33%;
  width: 33.33%;
  padding: 0 2rem;

  @media (max-width: 1000px) {
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
    padding: 0;
  }

  @media (max-width: 700px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 1rem;
    display: flex;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }

    h2 {
      color: ${theme.colors.primary.base};
    }
  }
`;

const Image = styled.div`
  flex-shrink: 0;
  font-size: 0;
  overflow: hidden;

  @media (max-width: 700px) {
    width: 30%;

    img {
      width: 200%;
      max-width: none;
      transform: translateX(-25%);
    }
  }

  img {
    margin-bottom: 0;
    transition: ${theme.transitions.default};
  }
`;

const Info = styled.div`
  background: ${theme.colors.white.base};
  color: ${theme.colors.black.base};
  display: flex;
  padding: 2rem 0;
  text-align: left;

  @media (max-width: 700px) {
    padding-top: 0;
    padding-left: 2rem;
    display: block;
  }
`;

const Date = styled.div`
  @media (max-width: 700px) {
    display: flex;
    align-items: center;
  }
  .day {
    display: flex;

    @media (max-width: 700px) {
      margin-bottom: 0.5rem;
    }

    span {
      margin-right: 3px;
    }
  }
  b {
    font-weight: normal;
    font-size: ${theme.fontSize.xxlarge};
    line-height: 1;
    margin-right: 2rem;

    @media (max-width: 700px) {
      font-size: ${theme.fontSize.large};
      line-height: 1.4;
      margin-right: 0.5rem;
    }
  }
  .time {
    color: ${theme.colors.slate.light30};
    font-size: ${theme.fontSize.xsmall};
    letter-spacing: ${theme.letterSpacing.heading};

    @media (max-width: 700px) {
      margin-bottom: 0.5rem;
    }
  }
`;

const Text = styled.div`
  h2 {
    font-weight: bold;
    font-size: ${theme.fontSize.medium};
    line-height: ${theme.lineHeight.heading};
    transition: ${theme.transitions.default};

    @media (max-width: 700px) {
      margin-bottom: 0.5rem;
    }
  }
  p {
    color: ${theme.colors.slate.light30};
    font-size: ${theme.fontSize.xsmall};
    letter-spacing: ${theme.letterSpacing.heading};
  }
`;

const EventCard = ({ event }) => (
  <Wrapper
    key={event.event_id}
    href={event.event_url}
    target="_blank"
    rel="noopener norefferer"
  >
    <Image>
      <img src={event.thumbnail_url} />
    </Image>
    <Info>
      <Date>
        <p className="day">
          <span>{event.started_at_detail.M} </span>
          <span>/</span>
          <b>{event.started_at_detail.DD}</b>
        </p>
        <p className="time">
          {event.started_at_detail.ddd} {event.started_at_detail.HH}:
          {event.started_at_detail.mm}
        </p>
      </Date>
      <Text>
        <h2>{event.title}</h2>
        <p>
          {event.address} {event.place}
        </p>
      </Text>
    </Info>
  </Wrapper>
);

export default EventCard;

EventCard.propTypes = {
  event: PropTypes.shape({
    event_id: PropTypes.string.isRequired,
    event_url: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    address: PropTypes.string,
    place: PropTypes.string,
    started_at_detail: PropTypes.shape({
      M: PropTypes.string.isRequired,
      DD: PropTypes.string.isRequired,
      ddd: PropTypes.string.isRequired,
      HH: PropTypes.string.isRequired,
      mm: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
