module.exports = {
  ja: {
    pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    title: 'Repro culture', // Navigation and Site Title
    titleAlt: 'Repro culture', // Title for JSONLD
    description: 'Repro cultureは“代えのきかない唯一のマーケティングプラットホーム”を目指すReproのカルチャーがわかるメディアです。',
    url: 'https://culture.repro.io', // Domain of your site. No trailing slash!
    siteUrl: 'https://culture.repro.io', // url + pathPrefix
    siteLanguage: 'ja', // Language Tag on <html> element
    // SEO
    logoForSEO: 'logo/logo.png',
    bannerForSEO: 'logo/banner.png',
    // JSONLD / Manifest
    favicon: 'static/logo/favicon.png', // Used for manifest favicon generation
    shortName: 'ReproCulture', // shortname for manifest. MUST be shorter than 12 characters
    author: 'Repro', // Author for schemaORGJSONLD
    themeColor: '#333744',
    backgroundColor: '#F4F3F3',
    twitter: '@reproio', // Twitter Username
  },
  en: {
    pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    title: 'Repro culture', // Navigation and Site Title
    titleAlt: 'Repro culture', // Title for JSONLD
    description: 'Repro culture is a medium where you can see the culture of Repro aiming to be "the one and only marketing Platform".',
    url: 'https://culture.repro.io', // Domain of your site. No trailing slash!
    siteUrl: 'https://culture.repro.io', // url + pathPrefix
    siteLanguage: 'en', // Language Tag on <html> element
    // SEO
    logoForSEO: 'logo/logo.png',
    bannerForSEO: 'logo/banner.png',
    // JSONLD / Manifest
    favicon: 'static/logo/favicon.png', // Used for manifest favicon generation
    shortName: 'ReproCulture', // shortname for manifest. MUST be shorter than 12 characters
    author: 'Repro', // Author for schemaORGJSONLD
    themeColor: '#333744',
    backgroundColor: '#F4F3F3',
    twitter: '@reproio', // Twitter Username
  }
};
