import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../../styles/prism';

const selectRandomly = edges => {
  const max = edges.length - 1;
  const min = 0;
  const targetIndex = Math.floor(Math.random() * (max + 1 - min)) + min;
  return edges[targetIndex];
};

const RandomCoverImage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const imageEdge = selectRandomly(data.allFile.edges);
      return <Img fluid={imageEdge.node.childImageSharp.fluid} {...props} />;
    }}
  />
);

export default RandomCoverImage;

const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "cover" } }) {
      edges {
        node {
          childImageSharp {
            fluid(
              maxWidth: 1280
              maxHeight: 333
              quality: 50
              traceSVG: { color: "#B6BCCF" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;
