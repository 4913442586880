import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { locales } from '../../i18n/locales'
import { LocaleContext, I18nContext } from '../../layouts/Layout'
import { Link } from 'gatsby'


const LocaleToggle = ({
  defaultSlug,
  supportedLangs,
}) => {
  if(!defaultSlug) {
    return null
  }

  if(!supportedLangs || supportedLangs.length <= 1) {
    return null
  }

  const i18n = useContext(I18nContext)
  const locale = useContext(LocaleContext)
  const toLink = (to) => {
    navigate(to)
  }

  const anotherLang = locales[supportedLangs.find(sl => sl != locale)]
  const to = anotherLang.default ? defaultSlug : `/${anotherLang.path}${defaultSlug}`

  return (
    <Link to={to}>
      {i18n.t(`lang.${anotherLang.locale}`)}
    </Link>
  )
}



export default LocaleToggle
