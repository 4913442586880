import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import prism from '../styles/prism';
import theme from '../../config/theme';

const Wrapper = styled.div`
  ${prism};
  padding-bottom: 6.5rem;

  @media (max-width: ${theme.breakpoints.s}) {
    padding: 2rem;
  }

  p,
  ul,
  ol,
  table,
  pre,
  .gatsby-highlight {
    margin-right: 6.5rem;
    margin-left: 6.5rem;
    margin-bottom: 2rem;

    @media (max-width: ${theme.breakpoints.s}) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  p,
  li {
    letter-spacing: -0.003em;
    --baseline-multiplier: 0.179;
    --x-height-multiplier: 0.35;
    font-size: ${theme.fontSize.medium};
    line-height: ${theme.lineHeight.article};
    code {
      padding: 0.2rem 0.5rem;
      margin: 0.5rem 0;
    }
  }

  li {
    margin-left: 2rem;
    p {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
    }

    ul,
    ol {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
  a:not(.gatsby-resp-image-link):not(.anchor) {
    color: ${theme.colors.slate.light40};
    border-bottom: 1px solid ${theme.colors.slate.light40};
    transition: ${props => props.theme.transitions.default.transition};
    text-decoration: none;
    padding-bottom: 2px;
    &:hover,
    &:focus {
      color: ${theme.colors.primary.base};
      border-color: ${theme.colors.primary.base};
    }
  }
  h1 {
    margin-top: 3rem;
  }
  h2 {
    margin-top: 3rem;
    font-size: ${theme.fontSize.xlarge};
    font-weight: bold;
    line-height: ${theme.lineHeight.heading};

    @media (max-width: ${theme.breakpoints.s}) {
      font-size: ${theme.fontSize.large};
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
    margin-right: 6.5rem;
    margin-left: 6.5rem;
    margin-bottom: 2rem;
    font-weight: bold;
    line-height: ${theme.lineHeight.heading};

    @media (max-width: ${theme.breakpoints.s}) {
      margin-right: 0;
      margin-left: 0;
    }

    a {
      box-shadow: none;
      border-bottom: none;
      &:hover,
      &:focus {
        background: none;
      }
    }
    &:hover {
      .anchor svg {
        opacity: 0.8;
      }
    }
  }

  table {
    width: calc(100% - 13rem);

    @media (max-width: ${theme.breakpoints.s}) {
      width: 100%;
    }
  }

  figure {
    margin-bottom: 3rem;
  }

  figcaption {
    color: ${theme.colors.slate.light30};
    font-size: ${theme.fontSize.xsmall};
    padding: 0.75rem;
    text-align: center;
  }

  blockquote {
    color: ${theme.colors.slate.light30};
  }
`;

const Content = ({ input }) => (
  <Wrapper dangerouslySetInnerHTML={{ __html: input }} />
);

export default Content;

Content.propTypes = {
  input: PropTypes.any.isRequired,
};
