import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';

const Wrapper = styled.a`
  display: flex;
  margin-bottom: 2rem;

  @media (max-width: ${theme.breakpoints.s}) {
    margin: 0 2rem 2rem;
    display: block;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }

    h2 {
      color: ${theme.colors.primary.base};
    }
  }
`;

const Image = styled.div`
  flex-shrink: 0;
  font-size: 0;
  background: ${theme.colors.slate.light20};
  overflow: hidden;

  @media (max-width: ${theme.breakpoints.l}) {
    width: 50%;
    position: relative;

    img {
      height: 100%;
      width: auto;
      max-width: none;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (max-width: ${theme.breakpoints.s}) {
    width: 100%;

    img {
      width: 100%;
    }
  }

  img {
    margin-bottom: 0;
    opacity: 0.9;
    transition: ${theme.transitions.default};
  }
`;

const Info = styled.div`
  background: ${theme.colors.white.base};
  color: ${theme.colors.black.base};
  display: flex;
  padding: 3rem;
  text-align: left;

  @media (max-width: ${theme.breakpoints.xs}) {
    padding: 2rem;
  }
`;

const Date = styled.div`
  .day {
    display: flex;

    span {
      margin-right: 3px;
    }
  }
  b {
    font-weight: normal;
    font-size: ${theme.fontSize.xxlarge};
    line-height: 1;
    margin-right: 2rem;

    @media (max-width: ${theme.breakpoints.xs}) {
      font-size: ${theme.fontSize.xlarge};
    }
  }
  .time {
    color: ${theme.colors.slate.light30};
    font-size: ${theme.fontSize.xsmall};
    letter-spacing: ${theme.letterSpacing.heading};
  }
`;

const Text = styled.div`
  h2 {
    font-weight: bold;
    font-size: ${theme.fontSize.large};
    line-height: ${theme.lineHeight.heading};
    transition: ${theme.transitions.default};
  }
  p {
    color: ${theme.colors.slate.light30};
    font-size: ${theme.fontSize.xsmall};
    letter-spacing: ${theme.letterSpacing.heading};
  }
`;

const EventCard = ({ event }) => (
  <Wrapper
    key={event.event_id}
    href={event.event_url}
    target="_blank"
    rel="noopener norefferer"
  >
    <Image>
      <img src={event.thumbnail_url} />
    </Image>
    <Info>
      <Date>
        <p className="day">
          <span>{event.started_at_detail.M} </span>
          <span>/</span>
          <b>{event.started_at_detail.DD}</b>
        </p>
        <p className="time">
          {event.started_at_detail.ddd} {event.started_at_detail.HH}:
          {event.started_at_detail.mm}
        </p>
      </Date>
      <Text>
        <h2>{event.title}</h2>
        <p>
          {event.address} {event.place}
        </p>
      </Text>
    </Info>
  </Wrapper>
);

export default EventCard;

EventCard.propTypes = {
  event: PropTypes.shape({
    event_id: PropTypes.string.isRequired,
    event_url: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    address: PropTypes.string,
    place: PropTypes.string,
    started_at_detail: PropTypes.shape({
      M: PropTypes.string.isRequired,
      DD: PropTypes.string.isRequired,
      ddd: PropTypes.string.isRequired,
      HH: PropTypes.string.isRequired,
      mm: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
