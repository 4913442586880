import React from 'react';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Container } from '../../layouts';
import theme from '../../../config/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LocalizedLink from '../atoms/LocalizedLink';

const Wrapper = styled.div`
  position: relative;
  text-align: right;
  margin-bottom: 8rem;

  @media (max-width: ${theme.breakpoints.s}) {
    margin-bottom: 10rem;
    text-align: center;
  }

  &:before {
    content: '';
    background: ${theme.colors.white.light};
    display: block;
    position: absolute;
    z-index: -2;
    top: 10rem;
    width: 80%;
    bottom: -3rem;
    animation: anime 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform-origin: left center;

    @media (max-width: ${theme.breakpoints.m}) {
      bottom: -4rem;
    }

    @media (max-width: ${theme.breakpoints.s}) {
      width: 100%;
      bottom: -6rem;
    }

    @keyframes anime {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }
`;

const Inner = styled.div`
  position: relative;
`;

const Image = styled.div`
  width: 65%;
  display: inline-block;

  @media (max-width: ${theme.breakpoints.m}) {
    width: 65%;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    width: 90%;
  }
`;

const Text = styled.div`
  position: absolute;
  top: 6rem;
  left: 0;
  background: ${theme.colors.white.base};
  padding: 4rem;
  z-index: 0;
  text-align: left;
  width: 40%;
  min-height: calc(100% - 12rem);
  height: 50%;

  @media (max-width: ${theme.breakpoints.l}) {
    padding: 3rem;
    top: 4rem;
    min-height: calc(100% - 8rem);
    width: 50%;
  }

  @media (max-width: ${theme.breakpoints.m}) {
    min-height: calc(100% - 4rem);
    width: 50%;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    padding: 2rem;
    width: 90%;
    top: auto;
    bottom: -2rem;
    min-height: 0;
    height: auto;
  }

  @media (max-width: ${theme.breakpoints.xs}) {
    min-height: 0;
  }

  h1 {
    margin-bottom: 0;
    line-height: ${theme.lineHeight.heading};
    font-size: 1.75rem;
    font-weight: bold;

    @media (max-width: ${theme.breakpoints.l}) {
      font-size: ${theme.fontSize.xlarge};
    }

    @media (max-width: ${theme.breakpoints.s}) {
      font-size: ${theme.fontSize.large};
    }

    a {
      color: ${theme.colors.black.base};

      &:hover {
        color: ${theme.colors.primary.base};
      }
    }
  }

  .slick-slider {
    height: 100%;
  }

  .slick-dots {
    bottom: -10rem;

    @media (max-width: ${theme.breakpoints.l}) {
      bottom: -6rem;
    }

    @media (max-width: ${theme.breakpoints.s}) {
      bottom: -5rem;
    }

    li {
      button {
        &:before {
          font-family: ${theme.fontFamily.body};
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background: ${theme.colors.slate.light20};
          opacity: 1;
          content: '';
        }
      }

      &.slick-active {
        button {
          &:before {
            background: ${theme.colors.primary.base};
          }
        }
      }
    }
  }
`;

class FeatureSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const { list } = this.props;
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      easing: 'ease-in-out',
      pauseOnHover: true,
      lazyLoad: true,
    };
    return (
      <Wrapper>
        <Container type="big">
          <Inner>
            <Image>
              <Slider
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}
                arrows={false}
                {...settings}
              >
                {list.map( node => (
                  <div key={`image_${node.id}`}>
                    <Img
                      fluid={node.frontmatter.cover.childImageSharp.fluid}
                    />
                  </div>
                ))}
              </Slider>
            </Image>
            <Text>
              <Slider
                asNavFor={this.state.nav1}
                ref={slider => (this.slider2 = slider)}
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
                dots={true}
                {...settings}
              >
                {list.map( node => (
                  <div key={`text_${node.id}`}>
                    <h1>
                      <LocalizedLink to={node.frontmatter.path}>
                        {node.frontmatter.title}
                      </LocalizedLink>
                    </h1>
                  </div>
                ))}
              </Slider>
            </Text>
          </Inner>
        </Container>
      </Wrapper>
    );
  }
}

FeatureSlider.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  list: PropTypes.array,
};

FeatureSlider.defaultProps = {
  title: false,
};

export default FeatureSlider
