import React, { useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';
import { I18nContext } from '../../layouts/Layout'

const Wrapper = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  font-size: ${theme.fontSize.xsmall};
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
  background: ${theme.colors.white.base};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  @media (max-width: ${theme.breakpoints.s}) {
    display: ${props => (props.show ? 'block' : 'none')};
    box-shadow: rgba(0, 0, 0, 0.2) 0px -5px 20px;
  }

  p {
    margin-bottom: 0;
    margin-right: 1rem;

    @media (max-width: ${theme.breakpoints.s}) {
      margin-bottom: 1rem;
    }

    a {
      color: ${theme.colors.slate.light40};
      border-bottom: 1px solid ${theme.colors.slate.light40};
    }
  }

  .deny {
    color: ${theme.colors.primary.base};

    &:hover {
      cursor: pointer;
      color: ${theme.colors.primary.dark};
    }
  }

  .accept {
    background: ${theme.colors.primary.base};
    padding: 5px;
    margin-left: 1rem;
    color: ${theme.colors.white.base};
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background: ${theme.colors.primary.dark};
    }
  }
`;

const CookieUsagePopup = () => {
  const [ show, setShow ] = useState(false)
  const i18n = useContext(I18nContext)

  function showToggle() {
    const agreed = Cookies.get('cookie_usage_accepted');
    const GA_TRACKING_ID = 'UA-49424310-18';

    if (typeof agreed === 'undefined') {
      // acceptもdenyでもない場合は表示させる
      setShow(true)
    } else if (agreed === '1') {
      // accept, トラッキングを許可済み
      // https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out?hl=ja
      window['ga-disable-' + GA_TRACKING_ID] = false;
      setShow(false)
    } else if (agreed === '0') {
      // トラッキング不可, 表示消去
      setShow(false)
    }
  }

  function deny() {
    Cookies.set('cookie_usage_accepted', '0');
    showToggle();
  };

  function accept() {
    Cookies.set('cookie_usage_accepted', '1');
    showToggle();
  };


  useEffect(() => {
    showToggle();
  },[])


  return (
    <Wrapper
      id="js-cookie-usage-popup"
      data-repro-layout-name="CookieUsagePopup"
      show={show}
    >
      <div>
        <p>
          {i18n.t("cookieUsagePopup.description")}
          <a href="https://repro.io/company/legal/cookie/" target="_blank">
          {i18n.t("cookieUsagePopup.detail")}
          </a>
        </p>
      </div>
      <div>
        <span className="deny" onClick={deny}>{i18n.t("cookieUsagePopup.button.deny")}</span>
        <span className="accept" onClick={accept}>{i18n.t("cookieUsagePopup.button.accept")}</span>
      </div>
    </Wrapper>
  );
}

export default CookieUsagePopup;

CookieUsagePopup.propTypes = {
  list: PropTypes.array,
};
