import React, { Fragment } from 'react';
import { ThemeProvider } from 'emotion-theming';
import { css, Global } from '@emotion/core';
import PropTypes from 'prop-types';
import { SEO, CookieUsagePopup } from 'components';
import { NavBar, Footer } from 'layouts';
import theme from '../../config/theme';
import headroom from '../styles/headroom';
import fonts from '../styles/fonts';
import i18n from '../i18n/i18n';

export const I18nContext = React.createContext({});
export const LocaleContext = React.createContext({});


const Layout = ({ children, headerWhite, locale, defaultSlug, supportedLangs }) => {
  i18n.changeLanguage(locale)

  return (
    <LocaleContext.Provider value={locale} >
      <I18nContext.Provider value={i18n} >
        <ThemeProvider theme={theme}>
          <Fragment>
            <Global
              styles={css`
                ${fonts}
                *,
                *:before,
                *:after {
                  box-sizing: inherit;
                }
                html {
                  text-rendering: optimizeLegibility;
                  overflow-x: hidden;
                  box-sizing: border-box;
                  -ms-overflow-style: scrollbar;
                  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                  -webkit-font-smoothing: subpixel-antialiased;
                  -moz-osx-font-smoothing: unset;

                  @media (max-width: ${theme.breakpoints.s}) {
                    font-size: 14px;
                  }
                }
                html,
                body {  
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  padding: 0;
                }

                body {
                  display: flex;
                  flex-direction: column;
                  box-sizing: border-box;
                  min-height: 100vh;
                  padding-top: 109px;

                  @media (max-width: ${theme.breakpoints.s}) {
                    padding-top: 64px;
                  }
                }
                a {
                  color: ${theme.colors.link};
                  transition: color 0.5s;
                  text-decoration: none;
                }
                a:hover {
                  text-decoration: none;
                  color: ${theme.colors.linkHover};
                }
                h1 {
                  font-family: ${theme.fontFamily.heading};
                }

                ${headroom}
              `}
            />
            <SEO />
            <NavBar
              headerWhite={headerWhite}
              defaultSlug={defaultSlug}
              supportedLangs={supportedLangs}
            />
            {children}
            <Footer />
            <CookieUsagePopup />
          </Fragment>
        </ThemeProvider>  
      </I18nContext.Provider>
    </LocaleContext.Provider>
  );
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  headerWhite: PropTypes.bool,
};
