import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Card from './Card'
import PrevNextTitle from '../atoms/PrevNextTitle'

const NestPrevCardsWrapper = styled.div`
  display: flex;
  flex-basis: calc(99.9% * 1 / 2 + 1rem);
  max-width: calc(99.9% * 1 / 2 + 1rem);
  width: calc(99.9% * 1 / 2 + 1rem);
  flex-direction: column;
  justify-content: space-between;
  margin: ${props => props.isPrev ? '0 0 0 -2rem' : '0 -2rem 0 0' };

  @media (max-width: 700px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
  }
`;

const PrevNextCard = ({post, isPrev}) => {
  const titleText = isPrev ? 'Previous Article' : 'Next Article';

  return (
    <NestPrevCardsWrapper isPrev={isPrev}>
    { post &&
      <>
        <PrevNextTitle title={titleText} isPrev={isPrev}/>
        <Card
          isTFullWidth={true}
          cover={post.frontmatter.cover.childImageSharp.fluid}
          path={post.frontmatter.path}
          title={post.frontmatter.title}
          date={post.frontmatter.date}
          tags={post.frontmatter.tags}
        />
      </>
    }
    </NestPrevCardsWrapper>
  );
}

export default PrevNextCard;

PrevNextCard.propTypes = {
  isPrev: PropTypes.bool.isRequired,
  post: PropTypes.shape({
    frontmatter: PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      tags: PropTypes.array.isRequired,
      cover: PropTypes.object.isRequired
    }).isRequired,
  }),
};
