import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';

const TitleText = styled.h1`
  color: ${theme.colors.slate.light20};
  letter-spacing: ${theme.letterSpacing.wide};
  font-family: ${theme.fontFamily.heading};
  font-size: ${props => props.size === 'small' ? theme.fontSize.xlarge : theme.fontSize.xxlarge};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 4rem;

  @media (max-width: ${theme.breakpoints.s}) {
    font-size: ${props => props.size === 'small' ? theme.fontSize.large : theme.fontSize.xlarge};
    margin-bottom: 2rem;
  }
`;

const Title = ({ title, size }) => {
  return <TitleText size={size}>{title}</TitleText>;
};

export default Title;

Title.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
};
