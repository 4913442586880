import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';

const PrevNextTitleText = styled.h1`
  color: ${theme.colors.slate.light20};
  letter-spacing: ${theme.letterSpacing.wide};
  font-family: ${theme.fontFamily.heading};
  font-size: ${theme.fontSize.large};
  text-transform: uppercase;
  text-align: ${props => props.isPrev ? 'left': 'right' };
  margin-bottom: 1rem;
  padding: 0 2rem;

  ${props => {
    return props.isPrev ? `
      &:before {
        content:'';
        display: inline-block;
        border: ${theme.fontSize.xxsmall} solid transparent;
        vertical-align: middle;
        border-right-color: #CBCBCB;
        margin-right: ${theme.fontSize.xxsmall};
      }
    ` : `
      &:after {
        content:'';
        display: inline-block;
        border: ${theme.fontSize.xxsmall} solid transparent;
        vertical-align: middle;
        border-left-color: #CBCBCB;
        margin-left: ${theme.fontSize.xxsmall};
      }
    `
  }}

  @media (max-width: 1000px) {
    padding: 0;
  }


  @media (max-width: ${theme.breakpoints.s}) {
    margin-bottom: 1rem;
    padding: 0 1rem;

    ${props => {
      return props.isPrev ? `
        &:before {
          margin-left: -${theme.fontSize.small};
        }
      ` : `
        &:after {
          margin-right: -${theme.fontSize.small};
        }
      `
    }}
  }
  @media (max-width: 700px) {
    margin-bottom: 2rem;
    text-align: center;

    ${props => {
      return props.isPrev ? `
        &:before {
          display: none;
        }
      ` : `
        &:after {
          display: none;
        }
      `
    }}

  }
`;

const PrevNextTitle = ({ title, size, isPrev}) => {
  return <PrevNextTitleText isPrev={isPrev}>{title}</PrevNextTitleText>;
};

export default PrevNextTitle;

PrevNextTitle.propTypes = {
  isPrev: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
};
