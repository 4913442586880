import React, { useContext } from 'react';
import styled from '@emotion/styled';
import theme from '../../config/theme';
import logo from '../../static/logo/repro-logo-colored.svg';
import Facebook from '../assets/facebook.svg';
import Twitter from '../assets/twitter.svg';
import { LocalizedLink } from 'components';
import { I18nContext } from './Layout'


const Wrapper = styled.footer`
  bottom: 0;
  background: ${theme.colors.white.base};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 5rem 2rem 5rem 1.5rem;
  position: relative;

  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
  }
`;

const StyledLink = styled(props => <LocalizedLink {...props} />)`
  display: flex;
  font-weight: 700;
  align-items: center;

  @media (max-width: ${theme.breakpoints.s}) {
    position: absolute;
    top: 4.5rem;
    left: 1.5rem;
  }

  img {
    argin: 1.5rem 0 1rem;
    height: 1.5rem;
    transition: ${theme.transitions.default};
    &:hover {
      opacity: 0.7;
    }
    @media (max-width: ${theme.breakpoints.s}) {
      margin: 5px;
    }
  }
`;

const SnsButtons = styled.div`
  padding-bottom: 1rem;
  text-align: right;

  a {
    margin-left: 0.5rem;
    &:hover {
      svg {
        fill: ${theme.colors.slate.base};
        transition: ${theme.transitions.default};
      }
    }
  }
  svg {
    height: 1.2rem;
    fill: ${theme.colors.slate.light30};
  }
`;

const Nav = styled.div`
  text-align: right;
  a {
    color: ${theme.colors.slate.light30};
    font-size: ${theme.fontSize.xsmall};
    transition: ${theme.transitions.default};
    &:hover {
      color: ${theme.colors.slate.base};
    }

    & + a {
      margin-left: 1.5rem;
    }
  }
`;

const Footer = () => {

  const i18n = useContext(I18nContext)
  
  return (
    <Wrapper>
      <StyledLink to="/">
        <img src={logo} alt="Repro Logo" />
      </StyledLink>
      <div>
        <SnsButtons>
          <a href="https://www.facebook.com/reproio/" target="_blank">
            <Facebook />
          </a>
          <a href="https://twitter.com/reproio" target="_blank">
            <Twitter />
          </a>
        </SnsButtons>
        <Nav>
          <a href="https://repro.io/company/about/" target="_blank">
            {i18n.t("footer.company")}
          </a>
          <a href="https://repro.io/company/legal/privacy/" target="_blank">
            {i18n.t("footer.privacyPolicy")}
          </a>
          <a href="https://repro.io/jp/company/legal/term/" target="_blank">
            {i18n.t("footer.termsOfUse")}
          </a>
        </Nav>
      </div>
    </Wrapper>
  );
}
export default Footer;
