import React from 'react';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Container } from '../../layouts';
import theme from '../../../config/theme';
import { RandomCoverImage } from '../../components';

const Wrapper = styled.div`
  position: relative;
  text-align: right;
  margin-bottom: 8rem;

  @media (max-width: ${theme.breakpoints.s}) {
    margin-bottom: 4rem;
  }

  &:before {
    content: '';
    background: ${theme.colors.white.light};
    display: block;
    position: absolute;
    z-index: -2;
    top: 6rem;
    width: 80%;
    bottom: -3rem;
    animation: anime 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform-origin: left center;

    @media (max-width: ${theme.breakpoints.s}) {
      top: -1rem;
      width: 100%;
      bottom: -1.5rem;
    }

    @keyframes anime {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }
`;

const Inner = styled.div`
  position: relative;
`;

const Image = styled.div`
  width: 70%;
  position: absolute;
  top: -4rem;
  right: 0;
  z-index: -1;

  @media (max-width: ${theme.breakpoints.m}) {
    width: 90%;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    width: 90%;
  }
`;

const Text = styled.div`
  background: ${theme.colors.white.base};
  padding: 4rem;
  z-index: 0;
  margin-top: 4rem;
  text-align: left;
  width: 40%;

  @media (max-width: ${theme.breakpoints.m}) {
    padding: 3rem;
    width: 50%;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    padding: 2rem;
    width: 90%;
    min-height: 150px;
  }

  @media (max-width: ${theme.breakpoints.xs}) {
    min-height: 0;
  }

  h1 {
    margin-bottom: 0;
    line-height: ${theme.lineHeight.heading};

    @media (max-width: ${theme.breakpoints.s}) {
      font-size: ${theme.fontSize.large};
    }
  }

  p {
    color: ${theme.colors.slate.light30};
    letter-spacing: ${theme.letterSpacing.wide};
    text-transform: uppercase;
    font-family: ${theme.fontFamily.heading};
    margin-bottom: 0.5rem;

    @media (max-width: ${theme.breakpoints.s}) {
      font-size: ${theme.fontSize.small};
    }
  }
`;

const Header = ({ title, subTitle }) => (
  <Wrapper id="header">
    <Container type="big">
      <Inner>
        <Image>
          <RandomCoverImage />
        </Image>
        <Text>
          {subTitle && <p>{subTitle}</p>}
          <h1>{title}</h1>
        </Text>
      </Inner>
    </Container>
  </Wrapper>
);

export default Header;

Header.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

Header.defaultProps = {
  title: false,
  subTitle: false,
};
