import React from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import styled from '@emotion/styled';
import theme from '../../../config/theme';

const Error = styled.p`
  color: ${theme.colors.primary.dark};
  font-size: ${theme.fontSize.small};
  margin-bottom: 0;
  margin-top: 0.5rem;
`;

const Hint = styled.p`
  color: ${theme.colors.slate.light20};
  font-size: ${theme.fontSize.small};
  margin-bottom: 0;
  margin-top: 0.5rem;
`;

const FormControlMessage = ({ name, hint }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? <Error>{error}</Error> : <Hint>{hint}</Hint>;
    }}
  />
);

export default FormControlMessage;

FormControlMessage.propTypes = {
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
};
