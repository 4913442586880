import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import theme from '../../../config/theme';
import LocalizedLink from '../atoms/LocalizedLink';

const Wrapper = styled.article`
  border-bottom: 1px solid ${theme.colors.slate.light10};

`;

const StyledLink = styled(LocalizedLink)`
  display: block;
  padding: 1.5rem 0;

  &:hover {
    h2 {
      color: ${theme.colors.primary.base};
    }
  }
`;

const Info = styled.div`
  span {
    color: ${theme.colors.slate.light30};
    font-size: ${theme.fontSize.small};
    margin-right: 5px;
  }
`;

const Title = styled.h2`
  font-size: ${theme.fontSize.large};
  margin-bottom: 0.5rem;
  color: ${theme.colors.black.base};
  transition: ${theme.transitions.default};
`;

const JobCard = ({ path, title, titleJp, employmentType }) => (
  <Wrapper>
    <StyledLink to={path}>
      <Info>
        <Title>{title}</Title>
        <span>{titleJp}</span>
        <span>{employmentType}</span>
      </Info>
    </StyledLink>
  </Wrapper>
);

export default JobCard;

JobCard.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleJp: PropTypes.string,
  employmentType: PropTypes.string.isRequired,
};
